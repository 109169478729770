import $ from "jquery";

function clamp(value, min, max) {
    if(value < min) {
        return min;
    } else if(value > max) {
        return max;
    } else {
        return value;
    }
}

function getClaimElements() {
    return $(".bl-claim, .bl-herohd, .bl-pageendhd");
}

$(function() {
    const $claimElems = getClaimElements();

    function updateClaims() {
        $claimElems.each(function() {
            const $claimElem = $(this);
            updateClaim($claimElem);
        });
    }

    function getAnimHFactor($claimElem) {
        const data = $claimElem.data("animhfactor");
        if(data) {
            const f = parseFloat(data);
            if(f > 0) {
                return f;
            }
        }
        return 1;
    }

    function updateClaim($claimElem) {
        const $text = $claimElem.find(".fixclaim");
        const $inner = $text.find(".inner");

        const rect = $claimElem.get(0).getBoundingClientRect();
        const winh = window.innerHeight;
        const winhhalf = winh/2;
        //const animh = winh*0.5;
        const animhfactor = getAnimHFactor($claimElem);
        const animh = (window.innerWidth < 640 ? winh*0.25 : winh*0.5)*animhfactor; 
        const startIn = rect.top-animh;
        //const endIn = startIn+animh;
        const startOut = rect.bottom;
        //const endOut = startOut+animh;
        //const scrollPos = window.scrollY;

        const pxIn = winhhalf-rect.top;
        const pxInEarlier = pxIn+animh;
        const inPct = clamp(pxInEarlier/animh, 0, 1);

        const pxOut = winhhalf-rect.bottom;
        const outPct = clamp(pxOut/animh, 0, 1);
        //console.log(rect);
        //console.log(inPct+" / "+outPct);

        if(outPct > 0) {
            const opacity = 1-outPct;
            const yShift = outPct*-winh/8;
            $text.css("opacity", opacity);
            $inner.css("transform", "translateY("+yShift+"px)");
        } else if(inPct > 0) {
            const opacity = inPct;
            $text.css("opacity", opacity);
            $inner.css("transform", "none");
        } else {
            $text.css("opacity", 0);
            $inner.css("transform", "none");
        }
    }

    $(window).on("scroll", function() {
        requestAnimationFrame(updateClaims);
    });
    updateClaims();
    $(window).on("load", updateClaims);
});