import { ready } from "./utils.js";

const body = document.body;

function updateYScrollClasses()
{
    if (window.scrollY > 5) {
        body.classList.add("scrollygt0");
        body.classList.remove("scrollyeq0");
    } else {
        body.classList.add("scrollyeq0");
        body.classList.remove("scrollygt0");
    }
}

ready(() => {
    document.addEventListener("scroll", updateYScrollClasses);
    updateYScrollClasses();
});
