import ScrollReveal from "scrollreveal";

//scrollreveal
ScrollReveal().reveal(".fade-in-bottom", {
  origin: "bottom",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  delay: 500,
  duration: 700,
  interval: 200
});

ScrollReveal().reveal(".fade-in-top", {
  origin: "top",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  delay: 500,
  duration: 700,
  interval: 200
});

ScrollReveal().reveal(".fade-in-top-delay", {
  origin: "top",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  delay: 500,
  duration: 700,
  interval: 200,
  delay: 500
});
